<template>
  <MyFavorites show-as-separate-page />
</template>

<script>
import MyFavorites from '@/components/partials/MyFavorites';

export default {
  name: 'MyFavoritesPage',
  components: { MyFavorites },
  metaInfo: {
    title: 'My Favourites',
  },
};
</script>
